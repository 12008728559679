import * as React from 'react';
// import styled from 'styled-components';
import { Header } from '../header/header';
import { SiteNav } from '../nav/sitenav';
import { SiteSocial } from '../social/sitesocial';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';

import './../../styles/sp8-mysite.scss';

export interface ILayoutProps {
    children?: React.ReactNode;
}

export const Layout: React.FC = function(props: ILayoutProps) {
    const [collapsed, setMenuCollapsed] = React.useState(false);
    const CollapseMenu = (e: any) => {
        if (collapsed === null || collapsed === false) {
            setMenuCollapsed(true);
        } else {
            setMenuCollapsed(false);
        }
    }

    library.add(fab, far, fas);

    return (
        <>
            <header id="site-header">
                <Header onLogoClick={CollapseMenu} headerCollapsed={collapsed}/>
            </header>
            <div id="site-body">
                <div id="site-nav-container" className={collapsed ? 'site-nav-collapsed' : ''}>
                    <SiteNav menuCollapsed={collapsed} />
                    <SiteSocial displayHorizontal={!collapsed}/>
                </div>
                
                <div id="body-container">
                    {props.children}
                    
                    <section id="site-footer">
                        &copy; Santiago Porras
                    </section>
                </div>
            </div>
        </>
    );
}
