import {useEffect, useState} from 'react';
import { Service } from './service';
import { IFeedArticleProps } from '../components/shared/articles';

interface IArticleList {
    items: IFeedArticleProps[];
}

interface IService {
    url: string;
}

export const ArticleService = function(props: IService) {
    let [result, setResult] = useState<Service<IArticleList>>({
        status: "loading"
    })

    useEffect(() => {
        fetch(props.url)
          .then(response => response.json())
          .then(response => setResult({ status: 'loaded', payload: response }))
          .catch(error => setResult({ status: 'error', error }));
      });

      return result;
}