import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    IconLookup,
    IconDefinition,
    findIconDefinition,
    IconName
  } from '@fortawesome/fontawesome-svg-core'
import siteNavData from "./sitenav.json";

interface MenuProps {
    menuCollapsed: boolean;
}

interface INavItemProps {
    icon: string;
    title: string;
    enabled: boolean; 
    target: string;
    link: string;
}

interface INavItem {
    navItem: INavItemProps;
}

const SiteNavItem = function (props: INavItem) {
    let faIconLookup: IconLookup;
    faIconLookup = { prefix: 'fas', iconName: props.navItem.icon as IconName }

    let faIconDefinition: IconDefinition = findIconDefinition(faIconLookup)

    if (props.navItem.enabled)
    {
        return (
            <li className="nav-item">
                <a className="nav-item-link" data-enabled={props.navItem.enabled} href={props.navItem.link} target={props.navItem.target}>
                    <FontAwesomeIcon icon={faIconDefinition} className="nav-item-icon"/>
                    <span className="nav-item-text">{props.navItem.title}</span> 
                </a>
            </li>
        );
    } else {
        return (<></>);
    }
}

export const SiteNav = function(props: MenuProps) {
    return (
        <nav id="site-nav" className={props.menuCollapsed ? 'site-nav-collapsed' : ''}>
            <ul id="site-menu-side">
                {siteNavData.navigationItems.map((item, index) => {
                    return <SiteNavItem navItem={item} 
                                    key={index} />
                })}
            </ul>
        </nav>
    );
}