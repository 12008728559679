import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SocialProps {
    displayHorizontal: boolean;
}

export const SiteSocial = function(props: SocialProps) {
    // eslint-disable-next-line
    return  <div id="site-social" className={props.displayHorizontal ? 'horizontal' : 'vertical'}>
                <a className="social-item" href="http://blog.santiagoporras.com" target="_blank" rel="noopener noreferrer" title="Blog"> 
                    <FontAwesomeIcon icon="blog"/>
                </a>
                <a className="social-item" href="http://twitter.com/saintwukong" target="_blank" rel="noopener noreferrer" title="@saintwukong">
                    <FontAwesomeIcon icon={['fab', 'twitter']}/>
                </a>
                <a className="social-item" href="https://www.linkedin.com/in/santypr/" target="_blank" rel="noopener noreferrer" title="Perfil en LinkedIn"> 
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']}/>
                </a>
                <a className="social-item" href="https://www.youtube.com/channel/UC4Ufxyi6_sLwL5sySfJJ3WA" target="_blank" rel="noopener noreferrer" title="Canal de YouTube"> 
                    <FontAwesomeIcon icon={['fab', 'youtube']}/>
                </a>
            </div>
}