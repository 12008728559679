import * as React from 'react';

interface HeaderProps {
    onLogoClick: (e: any) => void;
    headerCollapsed: boolean;
}

export const Header = function(props: HeaderProps) {
    return (
        <>
            <div id="site-logo-container" onClick={props.onLogoClick}>
                {props.headerCollapsed 
                    ? <img id="site-logo-compact" src="/images/chrome/santiago-porras-symbol.png" alt="Santiago Porras" /> 
                    : <img id="site-logo" src="/images/chrome/santiago-porras-logo.png" alt="Santiago Porras" />
                }
            </div>
        </>
    );
}