import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IAttributeProps {
    title: string;
    content: string;
}

export const Attribute = function(props: IAttributeProps) {
    return (
        <article className="attribute">
            <header className="attribute-header">
                <div className="attribute-icon-container">
                    <FontAwesomeIcon icon="fingerprint" className="attribute-icon"/>
                </div>
                <div className="attribute-title">{props.title}</div>
            </header>
            <div className="attribute-description">{props.content}</div>
            <footer className="attribute-footer">
                <FontAwesomeIcon icon="chevron-down" className="attribute-expand"/>
            </footer>
        </article>
    );
}
