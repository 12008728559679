import * as React from 'react';

export interface IArticle {
    title: string;
    abstract: string;
    picture: string;
    date: string;
}

export interface IFeedArticleProps {
    id: string;
    author: string;
    title: string;
    content_html: string;
    content_text: string;
    image: string;
    tags: string[];
    date_published: string;
    url: string;
}

export interface IFeedArticle {
    article: IFeedArticleProps;
    imageBaseUrl: string;   
}


export const ArticleMini = function (props: IFeedArticle) {
    let pictureStyle = {
        backgroundImage: "url(" + (props.article.image != null ? (props.imageBaseUrl + props.article.image) : ('/images/articles/nopicture.jpg')) + ")"
    }

    return (
        <article className="post-container">
            <a className="post-link" href={props.article.url} target="_blank" rel="noopener noreferrer" >
                <div className="post-image" style={pictureStyle}></div>
                <header className="post-title">{props.article.title}</header>
                <div className="post-info">{props.article.date_published}</div>
                <div className="post-summary">{props.article.content_text}</div>
                <footer>
                    <div className="post-social">
                        <i className="fa fa-comment-o" aria-hidden="true"></i>
                        <i className="fa fa-heart-o" aria-hidden="true"></i>
                    </div>
                </footer>
            </a>
        </article>
    );
}
