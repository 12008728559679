import * as React from 'react';
import styled from 'styled-components';

declare global {
   interface Window { 
      adsbygoogle: any; 
      adsbygoogleinfeed: any
   }
}

const StyledGoogleAdHorizontalAdaptative = styled.ins`
   display: inline-block;
   min-width: 400px;
   max-width: 970px;
   height: 90px;

   @media screen and (max-width: 1250px) {
      display: none;
   }
`;

const StyledGoogleAdInFeed = styled.ins`
   display: block;

   @media screen and (min-width: 1250px) {
      display: none;
   }
`;

export const GoogleAdHorizontalAdaptative = () => {
   React.useEffect(() => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
   }) 

   return (
      <>
         <StyledGoogleAdHorizontalAdaptative className="adsbygoogle"
               data-ad-client="ca-pub-7737670428559109"
               data-ad-slot="3845429098"
               data-ad-format="auto"
               data-full-width-responsive="true" />
      </>
   )
}

export const GoogleAdInFeed = () => {
   React.useEffect(() => {
      (window.adsbygoogleinfeed = window.adsbygoogleinfeed || []).push({});
   }) 

   return (
      <>
         <StyledGoogleAdInFeed className="adsbygoogleinfeed"
               data-ad-client="ca-pub-7737670428559109"
               data-ad-slot="7165898448"
               data-ad-layout-key="-fb+5w+4e-db+86"
               data-ad-format="fluid" />
      </>
   )
}

export default GoogleAdHorizontalAdaptative;