import * as React from 'react';
// eslint-disable-next-line
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { Layout } from './components/layout/layout';
import { ErrorComponent } from './components/error/error';
import { HomeComponent } from './components/home/home';
import { GamesComponent } from './components/games/games';

export const routes =
    <Layout>    
        <Switch>
            <Route exact path='/' component={HomeComponent} />
            <Route exact path='/Games' component={GamesComponent} />
            <Route exact path='/error' component={ErrorComponent} />
        </Switch>
    </Layout>;