import * as React from 'react';
import styled from 'styled-components';
import gamesData from "./games.json";
import GoogleAdHorizontalAdaptative, { GoogleAdInFeed } from '../googleads/googleads';

const StyledGameContainer = styled.iframe`
   border: none;
   width: 100%;
   min-height: 900px;
`;

const StyledGameList = styled.section`
   display: flex;
   flex-flow: row no-wrap;
   justify-content: space-between;
`;

const StyledGameLink = styled.article`
   flex: 1 1 auto;
   margin: 12px;
   padding: 24px;
   background: rgb(26, 177, 198);
   color: #f0f0f0;
   text-align: center;
   border-radius: 6px;
   
   &:hover {
      cursor: pointer;
      background: rgb(11, 214, 224);
      color: #fff;
   }
`;

interface IGameItem {
   gameItem: IGameProps;
   selectGame: (item: HTMLElement, selectedGame: IGameProps) => any;
}

interface IGameProps {
   title: string;
   link: string;
   icon: string;
}

const GameLink = (props: IGameItem) => {
   const onGameClick = (event: React.MouseEvent<HTMLElement>): any => {
      let item = event.target as HTMLElement;
      props.selectGame(item, props.gameItem);
   }

   return (
      <>
         <StyledGameLink onClick={onGameClick}>
            {props.gameItem.title}
         </StyledGameLink>
      </>
   )
}

interface IGamesListProps {
   selectGame: (item: HTMLElement, selectedGame: IGameProps) => any;
}

const GamesList = (props: IGamesListProps) => {
   return (
      <>
         <StyledGameList>
            {gamesData.gamesList.map((item, index) => {
               return <GameLink gameItem={item} key={index} selectGame={props.selectGame}/>
            })}
         </StyledGameList>
      </>
   )
}

const GamesBoard = () => {
   const [currentGame, setCurrentGame] = React.useState({title: "", link: "", icon:""});

   function selectGame(item: HTMLElement, selectedGame: IGameProps): any {
      setCurrentGame(selectedGame);
   }

   const displayCurrentGame = (currentGame.link !== null && currentGame.link !== '') ?
         <StyledGameContainer src={currentGame.link} /> :
         <div></div>;

   return (
      <div id="games-board">
         <header className="layout-header-container">
            <GamesList selectGame={selectGame} />
         </header>
         <div className="layout-body-container">
            {displayCurrentGame}
         </div>
      </div>
   )
}

export const GamesComponent = () => {
   return (
      <section id="layout-games" className="layout-container">
         {/* <GoogleAdHorizontalAdaptative /> */}
         <GoogleAdInFeed />
         <GamesBoard />
         <GoogleAdHorizontalAdaptative />
      </section>
   )
}