import * as React from 'react';
import homeData from "./content.json";
import { ArticleMini } from '../shared/articles';
import { Attribute } from '../shared/attributes';
import { Skill } from '../shared/skills';
import {ArticleService} from '../../api/articleService';

import './../../styles/layouts/home.scss';

const HomeAttributes = function() {
    return (
        <section className="home-section-container">
            <header className="title">“Está bien investigar y resolver misteriosos asesinatos, pero no deberías necesitar hacerlo con el código. Simplemente deberías poder leerlo” – <i>Steve McConnell</i></header>
            <div className="attributes-container">
                {homeData.attributes.map((item, index) => {
                    return <Attribute title={item.title} content={item.content} key={index} />
                })}
            </div>
        </section>
    );
}

const HomeArticles = () => {
    const serviceUrl = "https://blog.santiagoporras.com/";
    const service = ArticleService({url: serviceUrl + "feed/json"});

    return (
        <div className="home-section-container">
            <section className="section-posts">
                <header className="home-section-header">
                    Artículos recientes
                </header>
                <div className="posts-container">
                    { service.status === 'loading' && <div>Loading...</div> }
                    { service.status === 'loaded' && service.payload.items.map((item, index) => {
                        if (index < 4)
                            return <ArticleMini article={item} key={index} imageBaseUrl={serviceUrl} />
                        return <></>
                    })}
                </div>
            </section>
        </div>
    )
}

const HomeBanner = function() {
    let bannerStyle = {
        backgroundImage: "url(/images/home/home-banner.jpg)"
    }

    return (
        // <div className="home-section-container">
            <section id="section-banner" style={bannerStyle}></section>
        // </div>
    );
}

const HomeSkills = function() {
    return (
        <div className="home-section-container">
            <section id="section-skills">
                <header className="home-section-header">
                    Experiencia en
                </header>
                <div id="skills-container">
                    {homeData.skills.map((item, index) => {
                        return <Skill skill={item} key={index} />
                    })}
                </div>
            </section>
        </div>
    );
}

export const HomeComponent = () => {
    return (
        <section id="layout-home" className="layout-container">
            <header className="layout-header-container">
                <HomeAttributes />
            </header>
            <div className="layout-body-container">
                <HomeArticles />
                <HomeBanner />
                <HomeSkills />
            </div>
        </section>
    );
}