import * as React from 'react';

interface ISkillProps {
    title: string;
    picture: string;
}

interface ISkill {
    skill: ISkillProps;
}

export const Skill = function(props: ISkill) {
    return (
        <img className="skill" src={props.skill.picture} alt={props.skill.title} />
    );
}
